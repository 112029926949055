import React from "react";
import { makeStyles } from "@mui/styles";

function TransparentBox({ children }) {
  const classes = styles();
  return <div className={classes.container}>{children}</div>;
}

export default TransparentBox;

//styles

const styles = makeStyles((theme) => ({
  container: {
    border: "1.5px solid white",
    borderRadius: "40px",
    backgroundImage:
      "radial-gradient(231% 135.8% at 0.9% 2.98%, rgba(217, 224, 255, 0.50) 0%, rgba(222, 215, 247, 0.19) 100%)",
    backdropFilter: "blur(2px)",
    minHeight: "10px",
    padding: "2vh 5vw",
    margin: " 0 2vw 2.5vh",
    zIndex: 2,
  },
}));
