import React from "react";
import { ContentText, HeaderText } from "./common/Fonts";
import { Grid } from "@mui/material";

const About = (props) => {
  return (
    <div>
      <Grid container style={{ padding: "0% 4%" }}>
        <Grid item xs={12}>
          <ContentText
            content={`Dive into a realm where reality transcends its boundaries, where learning becomes an adventure, and where innovation finds its canvas. Welcome to CHILL, a gateway to a new dimension of education and engagement. At the heart of CHILL's vision lies the ambition to empower libraries to offer their communities an unparalleled journey into the world of education through virtual reality (VR) and augmented reality (AR).`}
          />
        </Grid>
        <ContentText content={``} />
      </Grid>
      <Grid container>
        <Grid item xs={12} style={{ position: "relative" }}>
          <div style={{ position: "absolute" }}>
            {" "}
            <img src="assets/boxes.svg" />
          </div>
          <div>
            <HeaderText content={`A Virtual Odyssey Unveiled`} />
          </div>
        </Grid>
      </Grid>
      <Grid container style={{ padding: "0% 4%" }}>
        <Grid item xs={12}>
          <ContentText
            content={`CHILL's Immersive Experiences and Learning Package isn't just a concept; it's a paradigm shift. It equips libraries and community organisations with the tools to offer their patrons a taste of the extraordinary. Step into a world where history comes alive, science takes you to the cosmos, and art surrounds you in breathtaking landscapes. This package isn't just about content; it's about experiencing knowledge in its truest form.`}
          />
        </Grid>
        <Grid item xs={12}>
          <img src="assets/aboutUs/image1.svg" alt="Image" width={"100%"} />
        </Grid>
      </Grid>
      <Grid container style={{ padding: "0% 4%" }}>
        <Grid item xs={12}>
          <HeaderText content={`Reshaping Learning, Together`} />
        </Grid>
        <Grid item xs={12}>
          <ContentText
            content={`Our groundbreaking pilot is built on the foundation of four transformative objectives:`}
          />

          <ContentText
            title={`1. Embracing Education, Redefined:`}
            content={` With CHILL, learning takes a leap beyond textbooks. Our package merges education and engagement, transforming abstract concepts into immersive realities that spark curiosity and deepen understanding.

`}
          />

          <ContentText
            title={`2. Bridging Today's Digital Frontier:`}
            content={`We believe in a world where digital skills aren't a luxury but a necessity. CHILL's package fosters digital literacy by offering hands-on experience with VR and AR, ensuring that no one is left behind in the digital age.

`}
          />

          <ContentText
            title={`3. Igniting Innovators:`}
            content={` In the landscape of VR and AR, creativity knows no bounds. CHILL's Immersive Experiences and Learning Package sparks innovative thinking, encouraging users to explore, experiment, and embrace the art of possibility.

`}
          />

          <ContentText
            title={` 4. Forging Stronger Bonds:`}
            content={`Libraries have always been community anchors. CHILL amplifies this role by uniting library users, staff, and community members in an ecosystem of shared experiences, collaboration, and growth.`}
          />
        </Grid>
        <Grid item xs={12}>
          <img
            src="assets/aboutUs/secondImage.svg"
            alt="Image"
            width={"100%"}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} style={{ position: "relative" }}>
          <div style={{ position: "absolute" }}>
            {" "}
            <img src="assets/boxes.svg" />
          </div>
          <div style={{ padding: "4% 4% 0" }}>
            <HeaderText content={`Unveiling a Tapestry of Impact`} />
          </div>
        </Grid>
        <Grid item xs={12} style={{ padding: "0 4%" }}>
          <ContentText
            content={`CHILL's Immersive Experiences and Learning Package isn't just about the virtual; it's about creating tangible change: `}
          />
          <ContentText
            title={`A New Chapter for Communities:`}
            content={` Libraries, art galleries, museums, community centres and schools can evolve into hubs of exploration, becoming portals to worlds previously unreachable. `}
          />
          <ContentText
            title={`Empowering Tomorrow's Digital Citizens:`}
            content={` CHILL bridges the gap between technology and its users, empowering them to navigate the digital landscape with confidence.`}
          />
          <ContentText
            title={` Innovation Cultivated:`}
            content={` Through the magic of VR and AR, CHILL nurtures innovators who dare to push boundaries and pioneer new horizons. `}
          />
          <ContentText
            title={` Community United:`}
            content={` Connections deepen, collaborations flourish, and communities come together in shared experiences that redefine togetherness. `}
          />
          <ContentText
            title={` Cultural Heritage Celebrated:`}
            content={` CHILL weaves local heritage into the immersive tapestry, honouring traditions and sharing them in innovative ways. `}
          />
          <ContentText
            title={`Economic Empowerment: `}
            content={`CHILL isn't just shaping minds; it's shaping futures. By fostering digital skills, it paves the way for new opportunities in technology-driven industries.`}
          />
        </Grid>
        <Grid container item xs={12} style={{ padding: "0 4%" }}>
          <Grid item xs={12}>
            <ContentText
              content={`CHILL's Immersive Experiences and Learning Package isn't just a concept; it's a paradigm shift. It equips libraries and community organisations with the tools to offer their patrons a taste of the extraordinary. Step into a world where history comes alive, science takes you to the cosmos, and art surrounds you in breathtaking landscapes. This package isn't just about content; it's about experiencing knowledge in its truest form.`}
            />
          </Grid>
          <Grid item xs={12}>
            <div>
              <img src={"assets/aboutUs/image3.svg"} width="100%" />
            </div>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <HeaderText content={`About the Cogito Metaverse`} />
            </Grid>
            <Grid item xs={12}>
              <ContentText
                content={`CHILL is a feature provided by the Cogito Metaverse. The Cogito Metaverse represents an exciting leap into the future of digital experiences. Imagine a vast, interconnected virtual realm where technology seamlessly blends with daily life. It's a space where people from all corners of the globe can connect, collaborate, and explore without the usual limitations of physical distance. From building wealth and accessing finance to engaging in educational pursuits, the Cogito Metaverse opens up a realm of possibilities. It's like a bridge between reality and imagination, offering a chance to interact with others and immerse oneself in activities ranging from business to entertainment. As technology continues to evolve, the Cogito Metaverse stands as a pioneering platform that could shape the way we communicate, learn, and engage with the world around us.`}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default About;
