import React, { useState } from "react";
import { Grid } from "@mui/material";
import TransparentBox from "./common/TransparentBox";
import { ContentText } from "./common/Fonts";
import { makeStyles } from "@mui/styles";
import { ToastContainer, cssTransition, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const bounce = cssTransition({
  enter: "animate__animated animate__bounceIn",
  exit: "animate__animated animate__bounceOut",
});
export const ContactUs = () => {
  const classes = styles();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    description: "",
  });
  const [message, setMessage] = useState();
  const hanldeFormData = (e, field) => {
    setFormData((oldData) => {
      return { ...oldData, [field]: e.target.value };
    });
  };
  const notify = (message, type) => {
    console.log(type);
    switch (type) {
      case "success":
        console.log("first");
        toast(message);

      case "error":
        return toast("Error Notification", {
          transition: bounce,
        });
      default:
        break;
    }
  };
  const submitForm = async (e) => {
    e.preventDefault();
    // let data = {
    //   name: formData.name,
    //   email: formData.email,
    //   message: formData.description,
    // };
    try {
      let res = await fetch(
        `${process.env.REACT_APP_API_LINK}/system/mailSupport`,
        {
          method: "POST",
          body: JSON.stringify({
            name: formData.name,
            email: formData.email,
            message: formData.description,
          }),
          headers: { "content-type": "application/json" },
        }
      );
      let resJson = res.json();
      if (res.ok) {
        console.log(res);
        toast.success("Email sent to CHILL support.");
        setFormData("");
      } else {
        toast.error("Some error occured");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <Grid
        container
        style={{
          padding: "6%",
          position: "relative",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid item xa={12} style={{ position: "absolute", right: "0", top: 0 }}>
          <img src="assets/Home/Vector.svg" alt="" />
        </Grid>
        <Grid
          item
          xa={12}
          style={{ position: "absolute", left: "0", bottom: "0" }}
        >
          <img src="assets/Home/Vector2.svg" alt="" />
        </Grid>
        <Grid item xs={12}>
          <TransparentBox>
            <Grid container item className={classes.formContainer}>
              <Grid item xs={6}>
                <ContentText content={`Get In Touch`} />
                <ContentText
                  content={`We are here for you! How can we help?`}
                />
                <form
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onSubmit={submitForm}
                >
                  <input
                    type="text"
                    placeholder="Enter your name"
                    required
                    className={classes.inputFields}
                    onChange={(e) => hanldeFormData(e, "name")}
                  />

                  <input
                    type="email"
                    placeholder="Enter your email address"
                    required
                    className={classes.inputFields}
                    onChange={(e) => hanldeFormData(e, "email")}
                  />
                  <textarea
                    type="text"
                    placeholder="Go ahead, we are listening..."
                    multiline
                    rows="14"
                    cols="10"
                    wrap="soft"
                    className={classes.detailsField}
                    onChange={(e) => hanldeFormData(e, "description")}
                  />
                  <button className={classes.submitButton}>Submit</button>
                </form>
              </Grid>
              <Grid
                item
                xs={6}
                style={{
                  padding: "12%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img src="assets/contactPage.svg" />
              </Grid>
            </Grid>
          </TransparentBox>
        </Grid>
      </Grid>
      <ToastContainer
        position="bottom-left"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        draggable
        pauseOnHover={false}
        transition={bounce}
      />
    </div>
  );
};

const styles = makeStyles((theme) => ({
  inputFields: {
    margin: "3% 0%",
    padding: "2%",
    width: "100%",
    height: "45px",
    borderRadius: "8px",
    border: "5px",
    backgroundColor: "#F2F1FF",
    font: "Outfit",
    color: "#ABAAAA",
    fontSize: "0.8rem",
    fontWeight: "bold",
  },
  detailsField: {
    margin: "3% 0%",
    padding: "2%",
    width: "100%",
    height: "150px",
    borderRadius: "8px",
    border: "5px",
    backgroundColor: "#F2F1FF",
    textOverflow: "clip",
    font: "Outfit",
    color: "#ABAAAA",
    fontSize: "0.8rem",
    fontWeight: "bold",
  },
  submitButton: {
    background:
      "linear-gradient(to top , rgba(190, 121, 234, 1), rgba(145, 169, 255, 1))",
    height: "65px",
    borderRadius: "10px",
    border: "5px",
    width: "100%",
    color: "#fff",
    fontSize: "1.3rem",
    fontWeight: 500,
  },
  formContainer: {
    display: "flex",
    "@media(max-width:650px)": {
      flexDirection: "column",
    },
  },
}));
