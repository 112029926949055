import React from "react";
import { makeStyles } from "@mui/styles";

function ContentText({ content, CenterAlign, title }) {
  const classes = styles();
  return (
    <p
      className={classes.contentText}
      style={{ textAlign: CenterAlign == "center" ? "center" : "left" }}
    >
      <text
        style={{
          fontWeight: "bold",
          color: "#525151",
          fontSize: "24px",
        }}
      >
        {title}
      </text>
      {content}
    </p>
  );
}

function HeaderText({ content }) {
  const classes = styles();
  return <p className={classes.headerText}>{content}</p>;
}

export { ContentText, HeaderText };

const styles = makeStyles((theme) => ({
  contentText: {
    color: "#616161",
    fontFamily: "Outfit",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "44px",
  },
  headerText: {
    color: "#313131",
    textAlign: "center",
    fontFamily: "Outfit",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
    "@media (max-width:650px)": {
      fontSize: "25px",
    },
  },
}));
