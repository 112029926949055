import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { ContentText } from "./common/Fonts";

const Features = () => {
  const classes = styles();
  return (
    <div className={classes.mainContainer}>
      <Grid container spacing={2} className={classes.innerContainer1}>
        <Grid item xs={12} sm={8}>
          <p
            style={{
              fontWeight: "bold",
              color: "#606060",
              fontSize: "24px",
            }}
          >
            Immersive VR Experiences:
          </p>
          <ContentText
            content={
              "Dive into a world of unparalleled realism and wonder. CHILL brings you immersive VR experiences that blur the lines between reality and imagination, transporting you to breathtaking places and incredible adventures."
            }
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <div>
            <img
              src="assets/Fetaures/Girl-Image-With-BG.png"
              alt="vr experience image"
              className={classes.RightImage}
            />
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.innerContainer2}>
        <Grid itemxs={12} sm={4}>
          <div>
            <img
              src="assets/Fetaures/Hadset-with-BG.png"
              alt="
            Diverse Content image"
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={8}>
          <p
            style={{
              fontWeight: "bold",
              color: "#606060",
              fontSize: "24px",
            }}
          >
            Diverse Content Library:
          </p>
          <ContentText
            content={
              " Explore a vast treasure trove of content that caters to every interest and passion. From thrilling adventures to thought-provoking documentaries, CHILL's diverse content library ensures there's something extraordinary for everyone."
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.innerContainer1}>
        <Grid item xs={12} sm={8}>
          <p
            style={{
              fontWeight: "bold",
              color: "#606060",
              fontSize: "24px",
            }}
          >
            Intuitive User Interface:
          </p>
          <ContentText
            content={
              "Seamlessly navigate through CHILL's universe with our user-friendly interface. Discovering new experiences, managing your favourite, and exploring genres is a breeze, allowing you to focus on the magic of virtual reality."
            }
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <div className={classes.RightImage}>
            <img src="assets/Fetaures/Kid-Image-with-BG.png" alt="image" />
          </div>
        </Grid>
      </Grid>
      <Grid container className={classes.innerContainer2}>
        <Grid item xs={12} sm={4}>
          <div>
            <img
              src="assets/Fetaures/Hadset-2-with-BG.png"
              alt="vr experience image"
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={8}>
          <p
            style={{
              fontWeight: "bold",
              color: "#606060",
              fontSize: "24px",
            }}
          >
            Social Interaction & Collaboration:
          </p>
          <ContentText
            content={
              "Connect with fellow adventurers and friends. CHILL goes beyond solitary experiences, allowing you to collaborate, share discoveries, and create memories together in the immersive realm."
            }
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Features;

const styles = makeStyles((theme) => ({
  image: {
    bottom: 0,
    right: 0,
  },

  mainContainer: {
    // backgroundImage: "url(assets/Fetaures/gradientBg.png)",
    // overflow: "hidden",
    padding: "8% 2%",
    // borderBlockStyle: "groove",
  },

  innerContainer1: {
    padding: "1% 0%",
    backgroundImage: "url(assets/Fetaures/gradientBg.png)",
    backgroundPosition: "Right",
    backgroundRepeat: "no-repeat",
  },
  innerContainer2: {
    padding: "1% 0%",
    backgroundImage: "url(assets/Fetaures/gradientBg.png)",
    backgroundPosition: "start",
    backgroundRepeat: "no-repeat",
  },
  RightImage: {
    alignItems: "right",
  },
}));
