import { BrowserRouter, Routes, Route } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Header from "./common/Header";
import Footer from "./common/Footer";
import Home from "./Home";
import VrContent from "./vrContent";
import Features from "./Features";
import About from "./About";
import { ContactUs } from "./contactUs";

function App() {
  const classes = styles();
  return (
    <div className={classes.app}>
      <BrowserRouter basename="/">
        <Header />
        <Routes>
          <Route path="/" Component={Home} />
        </Routes>
        <Routes>
          <Route path="/about-us" Component={About} />
        </Routes>
        <Routes>
          <Route path="/features" Component={Features} />
        </Routes>
        {/* <Routes>
          <Route path="/vr-Content" Component={VrContent} />
        </Routes> */}
        <Routes>
          <Route path="/contact" Component={ContactUs} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

const styles = makeStyles((theme) => ({
  app: {
    background: "#D8DFFC",
  },
}));
