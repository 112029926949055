import React from "react";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { Link, useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";

function Header() {
  const classes = styles();
  const location = useLocation();
  const [openMenu, setOpenMenu] = useState(false);
  const headerMenus = [
    { name: "HOME", link: "/" },
    { name: "ABOUT US", link: "/about-us" },
    { name: "FEATURES", link: "/features" },
    // { name: "VR CONTENT", link: "/vr-content" },
    // { name: "BLOG", link: "/blog" },
  ];
  const handleResponsiveMenu = () => {
    setOpenMenu(!openMenu);
  };
  return (
    <Grid
      container
      className={openMenu ? classes.responsiveHeader : classes.headerContainer}
      spacing={openMenu ? 0 : 2}
    >
      <Grid item container spacing={2} xs={openMenu ? 12 : null}>
        <Grid item xs={1} className={classes.menuIcon}>
          <MenuIcon onClick={handleResponsiveMenu} />
        </Grid>
        <Grid item sm={2} xs={4}>
          <Link to="/">
            <img src="assets/logo.svg" style={{ width: "50px" }} />
          </Link>
        </Grid>
        {openMenu ? (
          <Grid item xs={6} textAlign="right">
            <Link to="/contact">
              <button className={classes.contactButton}>CONTACT</button>
            </Link>
          </Grid>
        ) : null}
      </Grid>

      <Grid
        item
        container
        sm={8}
        xs={openMenu ? 12 : null}
        spacing={2}
        justifyContent="center"
        alignContent="center"
      >
        <div
          className={openMenu ? classes.menuItemsResponsive : classes.MenuItems}
        >
          {headerMenus.map((item) => {
            return (
              <Grid item>
                <Link
                  to={item.link}
                  onClick={openMenu ? handleResponsiveMenu : null}
                >
                  <button
                    className={classes.headerMenuText}
                    style={{
                      borderBottom:
                        location.pathname == item.link && !openMenu
                          ? "3px solid #9369EE"
                          : null,
                    }}
                  >
                    {item.name}
                  </button>
                </Link>
              </Grid>
            );
          })}
        </div>
      </Grid>
      {openMenu ? null : (
        <Grid item xs={8} sm={3} alignSelf="center" textAlign="right">
          <Link to="/contact">
            <button className={classes.contactButton}>CONTACT</button>
          </Link>
        </Grid>
      )}
    </Grid>
  );
}

export default Header;

const styles = makeStyles((theme) => ({
  headerContainer: {
    height: "12vh",
    background: "transparent",
    padding: "1vh 5vw",
    "@media(max-width:650px)": {
      // paddingLeft: "4vw",
      alignItems: "center",
    },
  },
  responsiveHeader: {
    "@media(max-width:650px)": {
      height: "auto",
      background: "#fff",
      borderBottomRightRadius: "25px",
      borderBottomLeftRadius: "25px",
      // flexDirection: "column",
      alignItems: "center",
      padding: "3vh 0 0 3vw",
    },
  },
  menuIcon: {
    display: "none",
    "@media(max-Width:650px)": {
      // float: "left",
      display: "block",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  headerMenuText: {
    border: "10px",
    color: "#737373",
    fontFamily: "Open Sans",
    background: "transparent",
    border: "none",
    borderRadius: "10%",
    borderBottom: "3px solid transparent",
    fontWeight: "bolder",
    transition: "border-bottom 1s",
    "&:hover": {
      borderBottom: "3px solid #9369EE",
      cursor: "pointer",
    },
    "@media(max-width:650px)": {
      width: "100%",
      transition: "none",
      borderBottom: "none",
      padding: "2vh 0",
      fontSize: "1rem",
    },
  },
  MenuItems: {
    display: "flex",
    flexDirection: "row",
    "@media(max-width:650px)": {
      left: 0,
      position: "absolute",
      top: "70px",
      left: " -110%",
      textAgn: "start",
      width: "100%",
      transition: "0.7s ease-in-out",
      backgroundColor: "aliceblue",
      padding: " 10px",
    },
  },
  menuItemsResponsive: {
    display: "flex",
    flexDirection: "row",
    "@media(max-width:650px)": {
      display: "flex",
      flexDirection: "column",
    },
  },
  contactButton: {
    background: `linear-gradient(to right , rgb(190,121,234,0.4) ,rgb(145,169,235,0.4))`,
    border: "none",
    borderRadius: "5%",
    width: "50%",
    height: "4vh",
    color: "#737373",
    fontFamily: "Open Sans",
    fontWeight: "bolder",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));
