import React from "react";
import { makeStyles, useTheme } from "@mui/styles";
import { styled } from "@mui/material/styles";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { ContentText, HeaderText } from "./common/Fonts";
import TransparentBox from "./common/TransparentBox";

function Home() {
  const classes = styles();
  const theme = useTheme();
  return (
    <div
      style={{ display: "flex", alignItems: "center", flexDirection: "column" }}
    >
      <Grid container className={classes.div1}>
        <Grid item xs={12} sm={6}>
          <p className={classes.textGradient}>Empowering Education:</p>
          <p className={classes.textWhite}>Utilizing Reality with</p>
          <p className={classes.textWhite}>CHILL</p>
          {/* <button className={classes.buttonGradient}>Find out more</button> */}
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
            // height: "80vh",
          }}
        >
          <img src={"assets/HeroImage.png"} className={classes.img}></img>
        </Grid>
      </Grid>
      <div className={classes.div2}>
        <p className={classes.div2Text}>
          Fund, Promote and Invest in Innovative Education Strategies
        </p>
      </div>
      <Grid container className={classes.div3}>
        <Grid item sm={6}>
          <ContentText
            content={`“I've come to realise that the future of education goes beyond mere technology. By utilising virtual reality, it can resemble a virtual classroom where we come together to learn, explore, and collaborate. It's not solely about acquiring knowledge; it's about building a strong educational community, forging lasting connections, and enjoying the learning journey to the fullest!"`}
          />
          <ContentText content={"- Victor C."} />
        </Grid>
        <Grid item sm={6} className={classes.div3ImageContainer}>
          <img src={"assets/vrSet.png"} />
        </Grid>
      </Grid>
      <div className={classes.div4}></div>
      <HeaderText
        content={
          "Unveiling our Collaborative Education Model and Sustainable Impact"
        }
      />
      <Grid
        container
        xs={12}
        style={{ position: "relative", padding: "2.5vw" }}
      >
        <div className={classes.vectorTop}>
          <img src="assets/Home/Vector.svg" />
        </div>
        <TransparentBox>
          <ContentText
            content={
              "For potential benefactors and visionary contributors interested in championing education, CHILL offers a unique opportunity at the forefront of education in the virtual reality space. Our model is carefully designed to ensure accessibility and enduring influence. By supporting CHILL, you're not just endorsing advanced technology in the education space; you're nurturing an ecosystem of boundless experiences for all."
            }
            CenterAlign={"center"}
          />

          <Grid item xs={12} style={{ textAlign: "center" }}>
            <svg
              width="70"
              height="15"
              viewBox="0 0 70 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="7.30769"
                cy="7.30769"
                r="7.30769"
                fill="url(#paint0_linear_47_3)"
              />
              <circle
                cx="35.0001"
                cy="7.30769"
                r="7.30769"
                fill="url(#paint1_linear_47_3)"
              />
              <circle
                cx="62.6925"
                cy="7.30769"
                r="7.30769"
                fill="url(#paint2_linear_47_3)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_47_3"
                  x1="7.30769"
                  y1="1.08893e-07"
                  x2="14.6154"
                  y2="31.1538"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#CDC4FC" />
                  <stop offset="1" stop-color="#CADCFF" stop-opacity="0" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_47_3"
                  x1="35.0001"
                  y1="1.08893e-07"
                  x2="42.3078"
                  y2="31.1538"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#CDC4FC" />
                  <stop offset="1" stop-color="#CADCFF" stop-opacity="0" />
                </linearGradient>
                <linearGradient
                  id="paint2_linear_47_3"
                  x1="62.6925"
                  y1="1.08893e-07"
                  x2="70.0002"
                  y2="31.1538"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#CDC4FC" />
                  <stop offset="1" stop-color="#CADCFF" stop-opacity="0" />
                </linearGradient>
              </defs>
            </svg>
          </Grid>
          <ContentText
            content={
              "Our approach extends beyond revenue; it's about learning, community and shared aspirations. CHILL's sustainability hinges on collaborative support, underpinned by partnerships and shared values. While the service remains free for users, your contribution serves as a cornerstone, propelling our mission to reshape how people connect, learn, and experience through virtual reality."
            }
            CenterAlign={"center"}
          />
        </TransparentBox>{" "}
        <div className={classes.VectorBottom}>
          <img src="assets/Home/Vector2.svg" />
        </div>
      </Grid>

      <HeaderText content={`Invest in CHILL's Legacy of Accessibility`} />
      <div
        item
        style={{
          padding: "2vh 5vw",
        }}
      >
        <ContentText
          content={`Are you ready to shape the future of inclusive virtual reality experiences? By collaborating with CHILL, your involvement transcends financial backing – it empowers dreams to be realised. Your support lays the foundation for transformative content, immersive learning, and shared joy.`}
          CenterAlign={"center"}
        />
      </div>
      <Grid item xs={12} textAlign={"center"}>
        <img
          src={"assets/home/girl-wearing-vr-glasses.svg"}
          className={classes.img}
        />
      </Grid>
      <div
        item
        style={{
          padding: "2vh 5vw",
        }}
      >
        <ContentText
          content={`To explore how you can play a pivotal role in CHILL's mission and the vast potential for societal impact, we encourage you to connect with our dedicated team. As we navigate the landscape of virtual reality's evolution, your investment paves the way for a world where innovation is accessible to all. 
        
        `}
          CenterAlign={"center"}
        />
      </div>

      <TransparentBox>
        <ContentText
          content={`Reach out today to become a driving force that amplifies human connection, inspires learning, and leaves an enduring mark through CHILL. 
Your commitment resonates in every shared experience, creating a legacy that bridges possibilities for generations to come.`}
          CenterAlign={"center"}
        />
      </TransparentBox>
    </div>
  );
}

export default Home;

//styles

const styles = makeStyles((theme) => ({
  div1: {
    // height: "80vh",
    borderRadius: "20px",
    width: "95vw",
    padding: "0 5vw",
    background:
      "linear-gradient(to bottom right, rgb(190,121,234),rgb(145,169,255))",
    margin: "5vw",
    "@media (max-width:650px)": {
      // height: "auto",
    },
  },
  textGradient: {
    fontFamily: "Open Sans",
    fontWeight: "700",
    fontSize: "50px",
    background:
      "linear-gradient(270deg, rgba(50, 113, 236, 0.84) 12.89%, rgba(91, 1, 134, 0.63) 100.05%)",
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    lineHeight: "normal",
    "@media (max-width:650px)": {
      fontSize: "40px",
    },
  },
  textWhite: {
    fontFamily: "Open Sans",
    fontWeight: "600",
    color: "#FFFEFE",
    fontSize: "45px",
    lineHeight: "30%",
    "@media (max-width: 650px)": {
      fontSize: "35px",
      lineHeight: "100%",
    },
  },
  img: {
    maxWidth: "95%",
  },
  buttonGradient: {
    background: `linear-gradient(to bottom,rgb(149,166,254,1), rgb(189,124,236,1))`,
    boxShadow: "0px 4px 18px 0px rgba(180, 147, 255, 1)",
    border: "none",
    borderRadius: "10px",
    width: "45%",
    height: "10vh",
    color: "#FFFEFE",
    fontFamily: "Open Sans",
    fontWeight: "bolder",
    fontSize: "20px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  div2: {
    background:
      "linear-gradient(270deg, rgba(190, 121, 234, 0.1) 0%, rgba(145, 169, 255, 0.1) 100%)",
    height: "40px",
    borderRadius: "57px",
    margin: "4% 2%",
    width: "80vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "2.5vw",
    boxShadow: "0px 4px 18px 0px rgba(180, 147, 255, 1)",
  },
  div2Text: {
    fontFamily: "Outfit",
    fontSize: "28px",
    fontWeight: "400",
    textAlign: "center",
    "@media (max-width:650px)": {
      fontSize: "14px",
    },
  },
  div3: {
    width: "95vw",
    padding: "2.5vw  5vw",
  },
  div3ImageContainer: {
    display: "flex",
    justifyContent: "right",
    alignItems: "center",
    backgroundImage: "url(assets/Background.png)",
    backgroundSize: "95% 95%",
    backroundRepeat: "no-repeat",
  },
  div4: {
    background:
      "linear-gradient(to right , rgba(190, 121, 234, 1), rgba(145, 169, 255, 1))",
    // maxWidth: "100vw",
    width: "100vw",
    height: "10vh",
    borderRadius: "10px",
    position: "relative",
    // margin: "2.5vw",
  },
  DotsIcon: {
    fill: "linear-gradient(166.8deg, rgba(205, 196, 252, 1), rgba(202, 220, 255, 0))",
  },
  vectorTop: {
    position: "absolute",
    right: 0,
    top: -45,
    "@medial (max-width:650px)": {},
  },
  VectorBottom: {
    position: "absolute",
    bottom: 0,
    left: 0,
    "@media (max-width:650px)": {
      bottom: -20,
    },
  },
}));
